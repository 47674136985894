import dayjs from "dayjs"
import "dayjs/locale/pt-br"
import "dayjs/locale/ja"
import "dayjs/locale/en"

interface List {
  [key: string]: {
    [key: string]: string
  }
}

let lang = "ja"
let locale = "ja-JP"
let currency = "JPN"

const list: List = {
  ja: { pt_br: "日本語", en_us: "日本語", ja: "日本語" },
  en_us: {
    pt_br: "English",
    en_us: "English",
    ja: "English",
  },
  pt_br: { pt_br: "Português", en_us: "Português", ja: "Português" },
  github: { pt_br: "GitHub", en_us: "GitHub", ja: "GitHub" },
  google: { pt_br: "Google", en_us: "Google", ja: "Google" },
  terms: { pt_br: "Termo", en_us: "Terms", ja: "利用規約" },
  cookie_preferences_privacy: {
    pt_br: "Preferencias de cookie e privacidade",
    en_us: "Cookie Preferences Privacy",
    ja: "プライバシーポリシー",
  },
  drag_and_drop_photo: {
    pt_br: "Arraste e solte sua imagem aqui",
    en_us: "Drag and drop your image here",
    ja: "写真をドラッグアンドドロップしてください",
  },
  or_click_to_browse: {
    pt_br: "ou click para pesquisar",
    en_us: "or click to browse",
    ja: "ここをクリックしてください",
  },
  update_avatar: {
    pt_br: "Atualizar seu avatar",
    en_us: "Update your avatar",
    ja: "アイコンを更新",
  },
  // TODO: Typo identity
  indentity_verified: {
    pt_br: "Identidade verificada",
    en_us: "Identity verified",
    ja: "本人確認済み",
  },
  edit_profile: {
    pt_br: "Atualizar seu perfil",
    en_us: "Edit your profile",
    ja: "プロフィール編集",
  },
  edit_cover: {
    pt_br: "Editar capa",
    en_us: "Edit cover",
    ja: "画像を編集",
  },
  teams: {
    pt_br: "times",
    en_us: "teams",
    ja: "チーム名",
  },
  account: {
    pt_br: "Conta",
    en_us: "Account",
    ja: "アカウント",
  },
  belong_teams: {
    pt_br: "Pertence a um ou mais times",
    en_us: "Belong to one or more teams",
    ja: "所属しているチーム",
  },
  menu: {
    pt_br: "Menu",
    en_us: "Menu",
    ja: "メニュー",
  },
  cms: {
    pt_br: "CMS",
    en_us: "CMS",
    ja: "CMS",
  },
  cloud: {
    pt_br: "CLOUD",
    en_us: "CLOUD",
    ja: "CLOUD",
  },
  personal_info: {
    pt_br: "Informações pessoais",
    en_us: "Personal Details",
    ja: "プロフィール設定",
  },
  login_security: {
    pt_br: "Login & Segurança",
    en_us: "Login & Security",
    ja: "Login & Security",
  },
  payments: {
    pt_br: "Pagamentos",
    en_us: "Payment Methods",
    ja: "お支払い",
  },
  cms_plan: {
    pt_br: "CMS Seu plano",
    en_us: "Subscriptions",
    ja: "CMSプラン",
  },
  cloud_plan: {
    pt_br: "CLOUD Seu plano",
    en_us: "CLOUD Subscriptions",
    ja: "CLOUDプラン",
  },
  cms_members: {
    pt_br: "Membros do CMS",
    en_us: "CMS Members",
    ja: "CMSメンバー",
  },
  cloud_members: {
    pt_br: "Membros do CLOUD",
    en_us: "CLOUD Members",
    ja: "CLOUDメンバー",
  },
  notification: {
    pt_br: "Notificações",
    en_us: "Notification",
    ja: "Notification",
  },
  integration_application: {
    pt_br: "Aplicações integradas",
    en_us: "Integrated Application",
    ja: "Integrated Application",
  },
  view_profile: {
    pt_br: "Visualizar perfil",
    en_us: "View Profile",
    ja: "プロフィールを見る",
  },
  account_info: {
    pt_br: "Informações da conta",
    en_us: "Account Info",
    ja: "アカウント情報",
  },
  display_name: {
    pt_br: "Nome",
    en_us: "Full Name",
    ja: "アカウント名",
  },
  enter_your_display_name: {
    pt_br: "Entre com seu nome",
    en_us: "Enter your name",
    ja: "アカウント名を入力",
  },
  real_name: {
    pt_br: "Nome",
    en_us: "Name",
    ja: "氏名",
  },
  phone: {
    pt_br: "Telefone",
    en_us: "Phone",
    ja: "電話番号",
  },
  phone_number: {
    pt_br: "Número de telefone",
    en_us: "Phone Number",
    ja: "電話番号",
  },
  email: {
    pt_br: "Email",
    en_us: "Email",
    ja: "メールアドレス",
  },
  bio: {
    pt_br: "Bio",
    en_us: "Bio",
    ja: "プロフィール",
  },
  about_yourself_in_a_few_words: {
    pt_br: "Fale um pouco sobre voce em poucas palavras",
    en_us: "Tell us a little about yourself in a few words",
    ja: "あなたについて一言で",
  },
  lives_in: {
    pt_br: "Mora em",
    en_us: "Lives In",
    ja: "居住地",
  },
  language: {
    pt_br: "Idioma",
    en_us: "Language",
    ja: "言語",
  },
  social: {
    pt_br: "Social",
    en_us: "Social",
    ja: "SNS関連",
  },
  website: {
    pt_br: "Website",
    en_us: "Website",
    ja: "ウェブサイト",
  },
  your_site_url: {
    pt_br: "URL do seu site",
    en_us: "Your Site URL",
    ja: "サイトのURL",
  },
  twitter: {
    pt_br: "Twitter",
    en_us: "Twitter",
    ja: "Twitter",
  },
  twitter_username: {
    pt_br: "Usuário no twitter",
    en_us: "twitter username",
    ja: "twitter username",
  },
  update_profile: {
    pt_br: "Atualizar perfil",
    en_us: "Save",
    ja: "保存",
  },
  close_my_account: {
    en_us: "Encerrar minha conta",
    pt_br: "Close my account",
    ja: "アカウントを削除",
  },
  create_your_team_account: {
    pt_br: "Criar seu time",
    en_us: "Create your team's account",
    ja: "チームアカウントの作成",
  },
  team_account_will_help: {
    pt_br: "Criando uma conta para seu time ira agilizar seu trabalho",
    en_us:
      "Team account will help you to speed up your workflow and lets you unlock some amazing features",
    ja: "チームアカウントを使うと、ワークフローの高速化といくつかの機能を利用できます。",
  },
  manage_your_team: {
    pt_br: "Gerenciar seu time",
    en_us: "Manage your team",
    ja: "チームの管理",
  },
  multi_seat_license: {
    pt_br: "Licença para vários usuários",
    en_us: "Multi-seat license",
    ja: "複数名向けプラン",
  },
  quality_search: {
    pt_br: "Pesquisa de qualidade",
    en_us: "Quality search",
    ja: "品質調査",
  },
  create_team: {
    pt_br: "Criar um time",
    en_us: "Create Team",
    ja: "チーム作成",
  },
  members: {
    pt_br: "membros",
    en_us: "members",
    ja: "人",
  },
  name: {
    pt_br: "Nome",
    en_us: "Name",
    ja: "名前",
  },
  email_address: {
    pt_br: "Endereço de Email",
    en_us: "Email",
    ja: "メールアドレス",
  },
  member_role: {
    pt_br: "Cargo",
    en_us: "Role",
    ja: "役割",
  },
  action: {
    pt_br: "Ação",
    en_us: "Action",
    ja: "操作",
  },
  cancel: {
    pt_br: "Cancelar",
    en_us: "Cancel",
    ja: "キャンセル",
  },
  team_name: {
    pt_br: "Nome do time",
    en_us: "Name",
    ja: "チーム名",
  },
  please_enter_correct_team_name: {
    pt_br: "Favor entrar com o nome do time.",
    en_us: "Please enter correct team name. it will be shown in account.",
    ja: "正しいチーム名を入力してください。チーム名はプロフィールに表示されます。",
  },
  team_size: {
    pt_br: "Tamanho do time",
    en_us: "Team Size",
    ja: "チーム規模",
  },
  your_crew: {
    pt_br: "Membros",
    en_us: "Your Crew",
    ja: "メンバー",
  },
  team_name_required: {
    pt_br: "Nome do time obrigatório",
    en_us: "team name required",
    ja: "チーム名は必須項目です",
  },
  input_error: {
    pt_br: "Erro de digitação",
    en_us: "Input error",
    ja: "入力エラーです",
  },
  loading: {
    pt_br: "Carregando...",
    en_us: "Loading...",
    ja: "読込中...",
  },
  loading_figma1: {
    pt_br: "Login efetuado com sucesso.",
    en_us: "You are now logged in successfully.",
    ja: "ログインに成功しました。",
  },
  loading_figma2: {
    pt_br: "Você pode fechar esta janela agora",
    en_us: "You can safely close this window.",
    ja: "ウィンドウを閉じて Figma に戻ってください。",
  },
  subscription_plans: {
    pt_br: "Planos de assinatura",
    en_us: "Subscription Plans",
    ja: "サブスクリプションプラン",
  },
  recent_invoices: {
    pt_br: "Faturas recentes",
    en_us: "Recent Invoices",
    ja: "直近の請求書",
  },
  membership: {
    pt_br: "Filiação",
    en_us: "Membership",
    ja: "メンバーシップ",
  },
  no_limited: {
    pt_br: "Sem limite",
    en_us: "No limit",
    ja: "無制限",
  },
  next_billing_date: {
    pt_br: "Data da proxima fatura",
    en_us: "Next billing date",
    ja: "次回請求日",
  },
  price: {
    pt_br: "Preço",
    en_us: "Price",
    ja: "金額",
  },
  per_month: {
    pt_br: "/mês",
    en_us: "/month",
    ja: "/ 月",
  },
  included_bandwidth: {
    pt_br: "Quantidade de banda incluso em GB",
    en_us: "Included bandwidth in GB",
    ja: "帯域幅（GB）",
  },
  included_concurrent_builds: {
    pt_br: "Compilações simultâneas",
    en_us: "Included concurrent builds",
    ja: "並行ビルド数",
  },
  included_build_time_in_minutes: {
    pt_br: "Time de compilação incluso",
    en_us: "Included build time in minutes",
    ja: "ビルド時間（分）",
  },
  subscription_fee: {
    pt_br: "Taxa de inscrição",
    en_us: "Subscription fee",
    ja: "サブスクリプション費用",
  },
  additional_user: {
    pt_br: "Usuário adicional",
    en_us: "Additional user",
    ja: "ユーザーの追加",
  },
  additional_bandwidth_per_10gb_unit: {
    pt_br: "Banda adicionar por unidades de 10GB",
    en_us: "Additional bandwidth per 10GB unit",
    ja: "帯域幅の追加（10GB単位）",
  },
  ending_balance: {
    pt_br: "Balanço final",
    en_us: "Ending balance",
    ja: "期末残高",
  },
  invoice_date: {
    pt_br: "Data da fatura",
    en_us: "Invoice Date",
    ja: "請求日",
  },
  invoice_description: {
    pt_br: "Descrição",
    en_us: "Description",
    ja: "請求先",
  },
  amount: {
    pt_br: "Quantidade",
    en_us: "Amount",
    ja: "金額",
  },
  invoice_number: {
    pt_br: "Número da fatura",
    en_us: "Invoice number",
    ja: "請求書番号",
  },
  invoice_status: {
    pt_br: "Status",
    en_us: "Status",
    ja: "ステータス",
  },
  print_invoice: {
    pt_br: "Imprimir fatura",
    en_us: "Print invoice",
    ja: "請求書の印刷",
  },
  plan_enterprise: {
    pt_br: "Marketing Pro",
    en_us: "Marketing Pro",
    ja: " マーケティング プロ",
  },
  plan_professional: {
    pt_br: "Marketing",
    en_us: "Marketing",
    ja: "マーケティング",
  },
  plan_basic: {
    pt_br: "CMS Basic",
    en_us: "CMS Basic",
    ja: "CMSベーシック",
  },
  plan_free: {
    pt_br: "Free",
    en_us: "Free",
    ja: "フリー",
  },
  invite_team: {
    pt_br: "Convidar membros para o time",
    en_us: "Invite Team Members",
    ja: "メンバーの招待",
  },
  invite: {
    pt_br: "Convidar",
    en_us: "Invite",
    ja: "招待",
  },
  edit_team: {
    pt_br: "Editar time",
    en_us: "Edit Team",
    ja: "チーム編集",
  },
  edit_member: {
    pt_br: "Editar usuário",
    en_us: "Edit Member",
    ja: "メンバー編集",
  },
  edited_member: {
    pt_br: "Editar usuário",
    en_us: "Edited Member",
    ja: "メンバー情報を更新しました",
  },
  delete_member: {
    pt_br: "Remover usuário",
    en_us: "Remove Member",
    ja: "メンバー削除",
  },
  delete_invitation: {
    pt_br: "Remover convite",
    en_us: "Remove Invitation",
    ja: "招待削除",
  },
  add_member: {
    pt_br: "Adicionar membro",
    en_us: "Add member",
    ja: "メンバーを招待",
  },
  invitable_user_count: {
    pt_br: "%s pessoas podem ser convidadas",
    en_us: "%s people can be invited",
    ja: "あと %s人招待可能",
  },
  add_new_seats: {
    pt_br: "Aumentar limite de membros",
    en_us: "Purchase memberships",
    ja: "シートの追加",
  },
  delete_invitation_button: {
    pt_br: "Apagar",
    en_us: "Remove",
    ja: "削除",
  },
  resend_invitation_button: {
    pt_br: "Reenviar",
    en_us: "Resend",
    ja: "再送信",
  },
  resend_complete_title: {
    pt_br: " Reenviar o convite",
    en_us: "Resend the invitation",
    ja: "招待を再送信しました",
  },
  resend_complete_message: {
    pt_br:
      "Convite enviado com sucesso, basta o(a) %s acessar sua conta no Spearly para aceitar o convite",
    en_us:
      "Invitation sent! now %s just need to access Spearly account to accept the invitation.",
    ja: "%sを再度招待しました。チームに参加するには、Spearlyアカウントにアクセスする必要があります。",
  },
  confirm_delete_member_message: {
    pt_br: "Confirma remover membro?",
    en_us: "Confirm remove member",
    ja: "削除すると元には戻せません。よろしいですか？",
  },
  please_enter_delete_team_name: {
    pt_br: "Favor entrar com o nome do time.",
    en_us: "Please enter the team's name.",
    ja: "チーム名を入力してください。",
  },
  invalid_email: {
    pt_br: "Email invalido",
    en_us: "Invalid email",
    ja: "メールアドレスの形式が正しくありません",
  },
  already_invited: {
    pt_br: "Convite já enviado",
    en_us: "already invited",
    ja: "既に招待済みです",
  },
  empty_roles: {
    pt_br: "Nenhum cargo",
    en_us: "role not set",
    ja: "役割が設定されていません。",
  },
  save: {
    pt_br: "Salvar",
    en_us: "Save",
    ja: "決定",
  },
  proceed_to_checkout: {
    pt_br: "Ir para o Checkout",
    en_us: "Proceed to Checkout",
    ja: "購入に進む",
  },
  update_payments: {
    pt_br: "Salvar",
    en_us: "Save",
    ja: "保存",
  },
  updating_payments: {
    pt_br: "Salvando",
    en_us: "Saving",
    ja: "保存中",
  },
  payment_method: {
    pt_br: "Método de pagamento",
    en_us: "Payment Methods",
    ja: "お支払い方法",
  },
  real_name_required: {
    pt_br: "Nome obrigatório",
    en_us: "Name required",
    ja: "氏名は必須項目です",
  },
  email_required: {
    pt_br: "Email obrigatório",
    en_us: "Email required",
    ja: "メールアドレスは必須項目です",
  },
  tel_required: {
    pt_br: "Número de telefone obrigatório",
    en_us: "Telephone number required",
    ja: "電話番号は必須項目です",
  },
  unknown_error: {
    pt_br: "Ocorreu um erro inesperado",
    en_us: "An unexpected error has occurred",
    ja: "予期せぬエラーが発生しました",
  },
  saved_profile: {
    pt_br: "Perfil salvo",
    en_us: "Profile saved",
    ja: "プロフィールを保存しました",
  },
  display_name_required: {
    pt_br: "Nome required",
    en_us: "Name required",
    ja: "アカウント名は必須項目です",
  },
  saved_payment_method: {
    pt_br: "Método de pagamento salvo",
    en_us: "Payment method saved",
    ja: "お支払い情報が保存されました",
  },
  expiration: {
    pt_br: "Vencimento",
    en_us: "Expiration",
    ja: "有効期限",
  },
  add_payment_method: {
    pt_br: "Adicionar método de pagamento",
    en_us: "Add payment method",
    ja: "新しいお支払い方法を追加",
  },
  add_credit_card: {
    pt_br: "Adicionar cartão de crédito",
    en_us: "Add credit card",
    ja: "クレジットカードを追加",
  },
  loading_payment_method: {
    pt_br: "Carregando métodos de pagamento...",
    en_us: "Loading...",
    ja: "お支払い情報を読み込み中です…",
  },
  loading_payment_form: {
    pt_br: "Carregando formulário de pagamento...",
    en_us: "Loading...",
    ja: "お支払い情報入力フォームを読み込み中です…",
  },
  failed_saving_payment_method: {
    pt_br: "Ocorreu um erro :( Favor tentar novamente em alguns minutos",
    en_us: "an error occurred :( Please try again in a few minutes",
    ja: "お支払い情報の保存に失敗しました",
  },
  delete_confirm: {
    pt_br: "Confirma apagar",
    en_us: "Confirm deletion",
    ja: "削除の確認",
  },
  delete_payment_method: {
    pt_br: "Remover método de pagamento",
    en_us: "Remove payment method",
    ja: "お支払い方法の削除",
  },
  delete_credit_card: {
    pt_br: "Remover cartão de crédito",
    en_us: "Delete Credit Card",
    ja: "クレジットカードの削除",
  },
  delete: {
    pt_br: "Remover",
    en_us: "Delete",
    ja: "削除",
  },
  please_enter_delete_word: {
    pt_br: "Favor digitar 'deletar'.",
    en_us: "Please type 'delete'.",
    ja: "「削除」と入力してください。",
  },
  deleted_payment_method: {
    pt_br: "Método de pagamento removido com sucesso",
    en_us: "Payment method removed",
    ja: "お支払い情報を削除しました",
  },
  back_payment_method: {
    pt_br: "Voltar ao método de pagamento",
    en_us: "Back to payment method",
    ja: "お支払い方法設定へ戻る",
  },
  page_not_found: {
    pt_br: "Essa página não existe 🤔",
    en_us: "Page not found 🤔",
    ja: "お探しのページが見つかりませんでした。",
  },
  searched_but_not_found: {
    pt_br: "Tentei encontrar mas não encontrei 😥",
    en_us: "I tried to, but couldn't find it 😥",
    ja: "懸命にページを探しましたが、見つけることができませんでした。",
  },
  back_to_top: {
    pt_br: "Voltar ao topo da página",
    en_us: "Back to Top",
    ja: "Topへ戻る",
  },
  edited_team: {
    pt_br: "Time editado",
    en_us: "Team Information is updated!",
    ja: "チーム情報を更新しました",
  },
  credit_card_information: {
    pt_br: "Informações do cartão de crédito",
    en_us: "Credit Card information",
    ja: "クレジットカード情報",
  },
  our_check_out_is_safe: {
    pt_br:
      "Nosso checkout é seguro. Suas informações pessoais e de pagamento são transmitidas com segurança por meio de criptografia de 128 bits. Não armazenamos nenhuma informação de cartão de pagamento em nossos sistemas.",
    en_us:
      "Our checkout is safe and secure. Your personal and payment information is securely transmitted via 128-bit encryption. We do not store any payment card information on our systems.",
    ja: "当社のチェックアウトは安全です。お客様の個人情報やお支払い情報は、128ビットの暗号化により安全に送信されます。当社のシステムには、いかなる支払カード情報も保存されません。",
  },
  credit_card: {
    pt_br: "Cartão de crédito",
    en_us: "Credit card",
    ja: "クレジットカード",
  },
  add_new_credit_card: {
    pt_br: "+ Adicionar método de pagamento",
    en_us: "+ Add payment method",
    ja: "＋ 新しいクレジットカードの追加",
  },
  ends_with_number: {
    pt_br: "Termina com %s",
    en_us: "Ends with %s",
    ja: "下4桁 %s",
  },
  ends_with_number_expired: {
    pt_br: "Termina com %s ( Expirado )",
    en_us: "Ends with %s ( Expired )",
    ja: "下4桁 %s (有効期限切れ)",
  },
  expire: {
    pt_br: "Expira",
    en_us: "Expire",
    ja: "有効期限",
  },
  expires_with_ym: {
    pt_br: "Expira %s/%s",
    en_us: "Expires %s/%s",
    ja: "有効期限 %s/%s",
  },
  do_not_have_authority_payment: {
    pt_br: "Você não tem autoridade para definir método de pagamento.",
    en_us: "You do not have the authority to set payment method.",
    ja: "お支払い情報設定のための権限がありません。",
  },
  close: {
    pt_br: "Fechar",
    en_us: "Close",
    ja: "閉じる",
  },
  time_to_improve_your_spearly_account: {
    pt_br: "Hora de melhorar sua conta Spearly?",
    en_us: "Time to improve your Spearly account?",
    ja: "Spearlyアカウントを更新しますか？",
  },
  you_are_about_to_have_more_firepower: {
    pt_br:
      "Você está prestes a ter mais poder de fogo com spearly enjoy agora.",
    en_us: "You are about to have more firepower with spearly enjoy now.",
    ja: "Spearlyをより快適に使うために使うために更新しようとしています。",
  },
  concurrent_builds: {
    pt_br: "Compilações simultâneas",
    en_us: "Concurrent Builds",
    ja: "並行ビルド",
  },
  see_plan_details: {
    pt_br: "Ver detalhes do plano",
    en_us: "See plan details",
    ja: "プランの詳細を見る",
  },
  // TODO: typo activated
  actived: {
    pt_br: "ativo",
    en_us: "activated",
    ja: "有効",
  },
  checkout: {
    pt_br: "checkout",
    en_us: "checkout",
    ja: "購入",
  },
  confirm_checkout: {
    pt_br: "Confirmar checkout",
    en_us: "Confirm Checkout",
    ja: "購入確認",
  },
  change: {
    pt_br: "Alterar",
    en_us: "Change",
    ja: "変更",
  },
  purchased_plan: {
    pt_br: "Plano comprado.",
    en_us: "Purchased Plan.",
    ja: "プランを購入しました。",
  },
  need_add_credit_card_purchase_plan: {
    pt_br: "É necessário registrar um cartão de crédito para comprar o plano.",
    en_us: "Credit card registration is required to purchase the plan.",
    ja: "プラン購入にはクレジットカードの登録が必要です。",
  },
  do_you_delete_credit_card: {
    pt_br: "Você tem certeza que deseja deletar esse cartão de crédito?",
    en_us: "Are you sure you want to delete this credit card?",
    ja: "このクレジットカードを削除しますか？",
  },
  after_deleting_card_cannot_purchase: {
    pt_br:
      "Após deletar esse cartão, você não poderá mais utilizá-lo em futuras compras.",
    en_us:
      "After deleting this card, you will not be able to use it on further purchases.",
    ja: "このカードを削除すると、購入には使用できなくなります。",
  },
  set_as_default: {
    pt_br: "Definir como padrão",
    en_us: "Set as Default",
    ja: "メインのお支払い方法に設定",
  },
  manage_all_your_saved_cards: {
    pt_br: "Gerencie todos os seus cartões salvos.",
    en_us: "Manage all your saved cards.",
    ja: "保存したすべてのカードを管理します。",
  },
  which_payment_method_do_you_want_to_use: {
    pt_br: "Qual método de pagamento você deseja usar?",
    en_us: "Which payment method do you want to use?",
    ja: "どのお支払い方法を利用しますか？",
  },
  your_payment_method_has_expired: {
    pt_br: "Seu método de pagamento expirou.",
    en_us: "Your payment method has expired.",
    ja: "お支払い方法の有効期限が切れています。",
  },
  your_payment_method_has_expired_please_add_payment_method: {
    pt_br:
      "Seu método de pagamento expirou. Por favor adicione um novo método de pagamento para continuar utilizando o sistema.",
    en_us:
      "Your payment method has expired please add a new payment method to continue using the system.",
    ja: "お支払い方法の有効期限が切れています。システムを引き続き使用するには、新しいお支払い方法を追加してください。",
  },
  dont_have_credit_card: {
    pt_br: "Você não tem nenhum cartão de crédito registrado.",
    en_us: "You don't have any payment methods available yet",
    ja: "まだクレジットカードを追加していません",
  },
  can_manage_and_control_your_payment_methods: {
    pt_br:
      "Lembre-se que aqui você pode gerenciar e controlar seus métodos de pagamento.",
    en_us:
      "Remember that here you can manage and control your payment methods.",
    ja: "ここで支払い方法を管理および制御できます。",
  },
  add_a_new_one: {
    pt_br: "Adicione um novo",
    en_us: "Add payment method",
    ja: "新しいカードを追加",
  },
  changed_as_default_payment: {
    pt_br: "Método de pagamento padrão alterado.",
    en_us: "Changed default payment method.",
    ja: "メインのお支払い方法を変更しました",
  },
  enter_add_seats: {
    pt_br: "Digite a quantidade de assentos a serem adicionados",
    en_us: "Amount",
    ja: "追加メンバー枠を入力",
  },
  billing_and_add_seats: {
    pt_br: "Faturamento e adição de assentos",
    en_us: "Purchase Memberships",
    ja: "請求 / メンバー枠の追加",
  },
  new_seat: {
    pt_br: "Novo assento",
    en_us: "New seat",
    ja: "新しいメンバー枠",
  },
  total: {
    pt_br: "Total",
    en_us: "Total",
    ja: "合計",
  },
  add_seats: {
    pt_br: "Adicionar assentos",
    en_us: "Purchase Memberships",
    ja: "メンバー枠の追加",
  },
  money_unit: {
    pt_br: "¥",
    en_us: "¥",
    ja: "¥",
  },
  would_you_like_to_pay_for_seat: {
    pt_br:
      "Gostaria de pagar uma taxa adicional de ¥%s para adicionar %s assentos?",
    en_us: "Would you like to pay an additional fee of ¥%s to add seat for %s?",
    ja: "¥%sの追加料金を支払って%s人分のメンバー枠を追加しますか？",
  },
  added_seats: {
    pt_br: "Assentos adicionados",
    en_us: "Added seats",
    ja: "メンバー枠が追加されました",
  },
  added_seats_desc1: {
    pt_br: "Let's invite new members.",
    en_us: "Let's invite new members.",
    ja: "新たなメンバーを招待しましょう。",
  },
  added_seats_desc2: {
    pt_br: "Vamos liderar o projeto para o sucesso com toda a equipe!",
    en_us: "Let's lead the project to success with the whole team!",
    ja: "チーム全員でプロジェクトを成功に導きましょう！",
  },
  seats_not_set: {
    pt_br: "Assentos a serem adicionados não definidos",
    en_us: "The Seats to add is not set",
    ja: "追加するメンバー枠が設定されていません",
  },
  purchase_seats: {
    pt_br: "Comprar assentos",
    en_us: "Purchase Seats",
    ja: "メンバー枠の購入",
  },
  switched_team: {
    pt_br: "Time alterado: '%s'",
    en_us: "Switched team: '%s'",
    ja: "チームを「%s」に切り替えました",
  },
  logout: {
    pt_br: "Logout",
    en_us: "Log out",
    ja: "ログアウト",
  },
  create_new_team: {
    pt_br: "Criar um novo time",
    en_us: "Create a new team",
    ja: "チームを作成",
  },
  member_role_with_service: {
    pt_br: "Cargo em %s",
    en_us: "Role in %s",
    ja: "%sでの役割",
  },
  created_team: {
    pt_br: "Time criado",
    en_us: "Created Team",
    ja: "チームを作成しました",
  },
  service: {
    pt_br: "Serviço",
    en_us: "Service",
    ja: "サービス",
  },
  role: {
    pt_br: "Cargo",
    en_us: "Role",
    ja: "役割",
  },
  owner: {
    pt_br: "Proprietário",
    en_us: "Owner",
    ja: "オーナー",
  },
  admin: {
    pt_br: "Admin",
    en_us: "Admin",
    ja: "管理者",
  },
  developer: {
    pt_br: "Desenvolvedor",
    en_us: "Developer",
    ja: "開発者",
  },
  editor: {
    pt_br: "Editor",
    en_us: "Editor",
    ja: "エディター",
  },
  writer: {
    pt_br: "Escritor",
    en_us: "Writer",
    ja: "ライター",
  },
  logged_in_as: {
    pt_br: "Logado como %s",
    en_us: "Logged in as %s",
    ja: "%s としてログイン中",
  },
  logout_as: {
    pt_br: "Não é %s? Logout aqui",
    en_us: "Not %s? Logout here",
    ja: "%s ではないですか？ ここからログアウトできます",
  },
  agreement_terms_title: {
    pt_br: "Concordar com os Termos de Serviço e Política de Privacidade",
    en_us: "Agreement the Terms & Service and Privacy Policy",
    ja: "利用規約およびプライバシーポリシーの同意",
  },
  agreement_terms_description: {
    pt_br:
      "Por favor, leia e concorde com os seguintes Termos de Serviço e Política de Privacidade antes de usar.",
    en_us:
      "Please read and agree to the following Terms & Service and Privacy Policy before usage.",
    ja: "下記の利用規約とプライバシーポリシーをお読みいただき、同意のうえで次へお進みください。",
  },
  agree_terms: {
    pt_br: "Concordo com os Termos de Serviço e Política de Privacidade",
    en_us: "I agree to the Terms & Service and Privacy Policy",
    ja: "利用規約とプライバシーポリシーに同意します",
  },
  go_next: {
    pt_br: "Ir para o próximo",
    en_us: "Go next",
    ja: "次へ進む",
  },
  removed_member: {
    pt_br: "Membro removido!",
    en_us: "Removed Member!",
    ja: "メンバーを削除しました",
  },
  removed_invitation: {
    pt_br: "Convite removido!",
    en_us: "Removed Invitation!",
    ja: "招待を取り消しました",
  },
  free_plan_team_attention: {
    pt_br: "Atenção!",
    en_us: "You can only create up to %s teams on the Free plan.",
    ja: "Freeプランのチームは%sつまでしか作成できません。",
  },
  invoices: {
    pt_br: "Faturas",
    en_us: "Invoices",
    ja: "お支払い情報",
  },
  payment_month: {
    pt_br: "Mês",
    en_us: "Month",
    ja: "請求日時",
  },
  payment_status: {
    pt_br: "Status",
    en_us: "Status",
    ja: "請求ステータス",
  },
  payment_value: {
    pt_br: "Valor",
    en_us: "Payment Value",
    ja: "請求金額",
  },
  paid: {
    pt_br: "Pago",
    en_us: "Paid",
    ja: "支払い済み",
  },
  download: {
    pt_br: "Download",
    en_us: "Download",
    ja: "ダウンロード",
  },
  no_invoice_history: {
    pt_br: "Nenhuma fatura",
    en_us: "No invoice history",
    ja: "請求履歴がありません",
  },
  no_card_information: {
    pt_br: "Nenhuma informação de cartão",
    en_us: "No card information",
    ja: "カード情報なし",
  },
  single_purchase: {
    pt_br: "Compra única",
    en_us: "Single Purchase",
    ja: "単体追加",
  },
  add_membership: {
    pt_br: "Adicionar membro",
    en_us: "Add Membership",
    ja: "メンバー枠追加",
  },
  add_form: {
    pt_br: "Adicionar formulário",
    en_us: "Add Form",
    ja: "フォーム追加",
  },
  add_custom_domain: {
    pt_br: "Adicionar domínio personalizado",
    en_us: "Add Custom Domain",
    ja: "カスタムドメイン追加",
  },
  membership_per_unit: {
    pt_br: "por 1 pessoa / mensal",
    en_us: "per 1 person / monthly",
    ja: "1名 / 月額",
  },
  form_per_unit: {
    pt_br: "por 1 formulário / mensal",
    en_us: "per 1 form / monthly",
    ja: "1フォーム / 月額",
  },
  custom_domain_per_unit: {
    pt_br: "por 1 domínio / mensal",
    en_us: "per 1 site / monthly",
    ja: "1 / 月額",
  },
  select: {
    pt_br: "Selecionar",
    en_us: "Select",
    ja: "選択",
  },
  plan_list: {
    pt_br: "Lista de planos",
    en_us: "Plan List",
    ja: "プラン一覧",
  },
  monthly: {
    pt_br: "Mensal",
    en_us: "Monthly",
    ja: "月額",
  },
  bandwidth: {
    pt_br: "Banda",
    en_us: "Bandwidth",
    ja: "通信量",
  },
  form_count: {
    pt_br: "Contagem de formulários",
    en_us: "Form Count",
    ja: "フォーム数",
  },
  membership_count: {
    pt_br: "Contagem de membros",
    en_us: "Membership Count",
    ja: "メンバー数",
  },
  abtest_count: {
    pt_br: "Contagem de A/B Test",
    en_us: "A/B Testing Count",
    ja: "A/Bテスト数",
  },
  content_count: {
    pt_br: "Contagem de conteúdo",
    en_us: "Content Count",
    ja: "コンテンツ数",
  },
  plan_detail: {
    pt_br: "Detalhes do plano",
    en_us: "Detail",
    ja: "プラン詳細",
  },
  build_time: {
    pt_br: "Tempo de construção",
    en_us: "Build Time",
    ja: "ビルド時間",
  },
  custom_domain: {
    pt_br: "Domínio personalizado",
    en_us: "Custom Domain",
    ja: "カスタムドメイン",
  },
  upgrade_plan_title: {
    pt_br: "Gostaria de atualizar seu plano?",
    en_us: "Would you like to update your plan?",
    ja: "プランをアップデートしますか?",
  },
  upgrade_plan_description: {
    pt_br:
      "A funcionalidade que pode ser usada e o limite de uso serão alterados.",
    en_us: "Please get more out of Spearly with a more powerful plan.",
    ja: "もっとパワフルなプランを利用して、よりSpearlyを楽しんでください",
  },
  downgrade_plan_title: {
    pt_br: "Gostaria de rebaixar seu plano?",
    en_us: "Would you like to downgrade your plan really?",
    ja: "本当にプランをダウングレードしますか？",
  },
  downgrade_plan_description: {
    pt_br:
      "A funcionalidade que pode ser usada e o limite de uso serão alterados.",
    en_us: "The feature that can be used and usage limit will be changed.",
    ja: "ご利用いただける機能や、利用上限が変更されます。",
  },
  proceed_to_payment: {
    pt_br: "Ir para o pagamento",
    en_us: "Proceed to payment",
    ja: "お支払いに進む",
  },
  change_plan: {
    pt_br: "Alterar plano",
    en_us: "Change plan",
    ja: "プランを変更する",
  },
  keep_current_plan: {
    pt_br: "Manter plano atual",
    en_us: "Keep current plan",
    ja: "現在のプランを維持する",
  },
  subscription: {
    pt_br: "Assinatura",
    en_us: "Subscription",
    ja: "継続購入",
  },
  subscription_loading: {
    pt_br: "Carregando assinatura...",
    en_us: "Purchasing in progress",
    ja: "購入処理中",
  },
  single_purchase_title: {
    pt_br: "Compra única de %s",
    en_us: "Subscription of %s",
    ja: "%s の継続購入",
  },
  single_purchase_description: {
    pt_br:
      "A funcionalidade que pode ser usada e o limite de uso serão alterados.",
    en_us: "Would you like to purchase ¥%s/month for subscription of %s?",
    ja: "¥%s/月で %s を継続購入しますか？",
  },
  purchase_completed: {
    pt_br: "Compra concluída!",
    en_us: "Successful subscription!",
    ja: "継続購入が完了しました",
  },
  please_reload: {
    pt_br:
      "Por favor, recarregue quando não houver cartão de exibição apesar de salvo.",
    en_us: "Please reload when no display card despite saved.",
    ja: "カード情報を登録しても表示されない場合には、ブラウザのリロードをお試しください。",
  },
  redirect_error_message: {
    pt_br: "",
    en_us: "You don't have permission to the page.",
    ja: "ページの閲覧権限がありません",
  },
  current_plan_quota_attention: {
    pt_br:
      "Atenção! O plano atual tem uma cota de %s. Se você exceder a cota, a funcionalidade será limitada.",
    en_us:
      "The count in parentheses is the amount added as purchased individually.",
    ja: "括弧内の数値は単体追加した数量です。",
  },
  contact: {
    pt_br: "Contato",
    en_us: "contact",
    ja: "お問い合わせ",
  },
  sign_up: {
    pt_br: "Cadastrar",
    en_us: "Sign Up",
    ja: "登録する",
  },
  sign_in: {
    pt_br: "Entrar",
    en_us: "Sign In",
    ja: "ログイン",
  },
  sign_in_with_google: {
    pt_br: "Entrar com Google",
    en_us: "Sign In with Google",
    ja: "Googleでログイン",
  },
  sign_in_with_github: {
    pt_br: "Entrar com GitHub",
    en_us: "Sign In with GitHub",
    ja: "GitHubでログイン",
  },
  sign_up_with_google: {
    pt_br: "Cadastrar com Google",
    en_us: "Sign Up with Google",
    ja: "Googleで登録する",
  },
  sign_up_with_github: {
    pt_br: "Cadastrar com GitHub",
    en_us: "Sign Up with GitHub",
    ja: "GitHubで登録する",
  },
  dont_have_account: {
    pt_br: "Ainda não tem uma conta?",
    en_us: "Don't have an account yet?",
    ja: "まだアカウントをお持ちでないですか？",
  },
  have_account_already: {
    pt_br: "Já tem uma conta?",
    en_us: "Do you have an account already?",
    ja: "すでにアカウントをお持ちですか？",
  },
  click_have_account_already: {
    pt_br: "Clique aqui se você já tem uma conta",
    en_us: "Click here if you already have account",
    ja: "すでにアカウントをお持ちの方はこちら",
  },
  open_application: {
    pt_br: "Abrir aplicativo.",
    en_us: "Open the application.",
    ja: "アプリケーションを開きます",
  },
  account_already_registered: {
    pt_br: "Conta já registrada",
    en_us: "Account already registered",
    ja: "アカウントは既に登録されています",
  },
  account_not_found: {
    pt_br: "Conta não encontrada",
    en_us: "Account don't register",
    ja: "アカウントは登録されていません",
  },
  welcome_to_spearly: {
    pt_br: "Bem-vindo ao Spearly",
    en_us: "Welcome to Spearly",
    ja: "ようこそSpearlyへ",
  },
  welcome_to_spearly_text1: {
    pt_br: "Comece gratuitamente",
    en_us: "Get started",
    ja: "まずは無料で",
  },
  welcome_to_spearly_text2: {
    pt_br: "Vamos começar",
    en_us: "for free",
    ja: "始めましょう",
  },
  signin_with_oauth: {
    pt_br: "Entrar com %s",
    en_us: "Sign In with %s",
    ja: "%sでログイン",
  },
  signup_with_oauth: {
    pt_br: "Cadastrar com %s",
    en_us: "Sign Up with %s",
    ja: "%sでサインアップ",
  },
  terms_confirm_text: {
    pt_br:
      "Ao clicar em 'Cadastrar com Google / GitHub' acima, você reconhece que leu, entendeu e concorda com os %s e %s do Spearly.",
    en_us:
      "By clicking 'Sign up at Google / GitHub' above, you acknowledge that you have read, understand and agree to Spearly's %s and %s.",
    ja: "上記の「Google / GitHubでサインアップ」をクリックすると、Spearlyの%sと%sを読んで理解し、同意したことになります。",
  },
  create_account1: {
    pt_br: "Comece criando uma conta",
    en_us: "Start by creating Account",
    ja: "まずはアカウント作成から",
  },
  create_account2: {
    pt_br: "Vamos começar",
    en_us: "Let's start",
    ja: "はじめましょう",
  },
  create_account_description1: {
    pt_br: "Através de um tutorial simples,",
    en_us: "Through a simple tutorial,",
    ja: "簡単なチュートリアルを通して、１つまでのABテストは",
  },
  create_account_description2: {
    pt_br: " você pode criar um AB test.",
    en_us: " up to 1 AB test can be run for free forever.",
    ja: "ずっと無料で実施できます。",
  },
  welcome_home: {
    pt_br: "Bem-vindo",
    en_us: "Welcome home",
    ja: "おかえりなさい",
  },
  sign_up_account: {
    pt_br: "Criar uma conta",
    en_us: "Create Account",
    ja: "アカウントを作成する",
  },
  plan_downgrade_quota_exceeded1: {
    pt_br:
      "Você não pode rebaixar porque o limite de uso do plano foi excedido.",
    en_us:
      "You cannot downgrade because the usage limit of plan has been exceeded.",
    ja: "プランの利用上限を超えているため、ダウングレードできません。",
  },
  plan_downgrade_quota_exceeded2: {
    pt_br: "Por favor, tente rebaixar após suspender e excluir.",
    en_us: "Please try downgrading after suspending and deleting them.",
    ja: "プラン内に収まるように利用停止または削除の後にダウングレードをお試しください。",
  },
  cannot_play_video: {
    pt_br:
      "Não é possível reproduzir o vídeo porque seu navegador não suporta a tag de vídeo.",
    en_us:
      "Cannot play video because your browser does not support the video tag.",
    ja: "ご利用のブラウザはvideoタグをサポートしていないため、再生できません",
  },
}

export function getDefaultLang() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let userLang: string = navigator.language || navigator.userLanguage
  userLang = userLang.toLowerCase()

  if (userLang === "la") {
    return "ja"
  }
  if (userLang.startsWith("pt")) {
    return "pt_br"
  }
  return "en_us"
}

export function weekDay(day: number, plural: boolean): string {
  return list[plural ? `ws_${day}` : `w_${day}`][lang]
}

export function setLang(newLang: string): void {
  if (!newLang) return

  lang = newLang

  if (newLang === "ja") {
    locale = "ja-JP"
    currency = "JPY"
    dayjs.locale("ja")
  } else if (newLang === "en_us") {
    locale = "en-US"
    currency = "USD"
    dayjs.locale("en")
  } else if (lang === "pt_br") {
    dayjs.locale("pt-br")
  }
}

export function getLang(): string {
  return lang
}

export function localeNumber(num: number): string {
  return num ? num.toLocaleString("pt-BR") : "0"
}

export function currencyFormat(
  value: string | number,
  customCurrency?: string,
): string {
  let number = value as number

  if (typeof value === "string") {
    number = parseFloat(value)
  }

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: customCurrency || currency,
  }).format(number)
}

export function t(word: string, ...replace: string[]): string {
  if (list[word] && list[word][lang]) {
    let msg = list[word][lang]
    for (let i = 0; i < replace.length; i++) {
      msg = msg.replace("%s", replace[i])
    }
    return msg
  }

  console.warn("Dict", word)

  return `::${word}::`
}
