import { GoogleOAuthProvider } from "@react-oauth/google"
import { notification } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import styled from "styled-components"

import { trackEvent } from "lib/eventTracker"

import {
  removeAuthData,
  updateSpearlyOauthTokenNonceAction,
} from "../actions/auth"
import { removeCurrentTeam } from "../actions/currentTeam"
import { loadProfile } from "../actions/profile"
import LoggedInProfile from "../components/Login/LoggedInProfile"
import LoginAttention from "../components/Login/LoginAttention"
import LoginHeader from "../components/Login/LoginHeader"
import LoginMain from "../components/Login/LoginMain"
import LoginOauthButton from "../components/Login/LoginOauthButton"
import GoogleOauthButton from "../components/molecules/GoogleOauthButton"
import { Loading } from "../components/molecules/Loading"
import { useLoginButtonVisibility } from "../hooks/useLoginButtonVisibility"
import { useOauth } from "../hooks/useOauth"
import { t } from "../lib/i18n"
import { getSpearlyToken } from "../lib/spearlyToken"
import useProfile from "../recoils/atoms/profile"
import useAuth from "../recoils/atoms/useAuth"

function Login(): JSX.Element {
  const { showGoogleButton, showGithubButton } = useLoginButtonVisibility()
  const { onGitHub } = useOauth("signin")
  const [profile, setProfile] = useRecoilState(useProfile)
  const setAuth = useSetRecoilState(useAuth)
  const token = getSpearlyToken()
  const searchParams = new URLSearchParams(window.location.search)
  const authedRedirectUri = searchParams.get("redirect_uri") || "/profile"
  const nonce = searchParams.get("nonce")
  const errorMessage = searchParams.get("error")
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""
  const [isFigmaPlugin, setIsFigmaPlugin] = useState(false)

  const redirectTo = async () => {
    try {
      await loadProfile(setAuth, setProfile)
      window.location.href = authedRedirectUri
    } catch (error) {
      console.error(error)
      removeAuthData()
    }
  }

  const updateSpearlyOauthTokenNonce = async (nonce: string) => {
    try {
      await updateSpearlyOauthTokenNonceAction(nonce)
      setIsFigmaPlugin(true)
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    trackEvent({ eventName: "Visit Login Page" })
  }, [])

  useEffect(() => {
    if (errorMessage === "401") {
      notification.error({
        message: t("account_not_found"),
      })
    }
  }, [errorMessage])

  useEffect(() => {
    if (!token) return

    if (nonce) {
      updateSpearlyOauthTokenNonce(nonce)
    } else {
      redirectTo()
    }
  }, [token])

  const isVisibleProfile = useMemo(() => {
    return token && profile && profile.name !== ""
  }, [token, profile])

  return nonce && token ? (
    <Loading isFigmaPlugin={isFigmaPlugin} />
  ) : (
    <GoogleOAuthProvider clientId={clientId}>
      <Root>
        <LoginHeader />
        <LoginMenu>
          <LoginMain isFigmaPlugin={!!nonce} />
          <ButtonList>
            {isVisibleProfile && <LoggedInProfile profile={profile} />}
            <GoogleOauthButton
              isVisible={showGoogleButton && !isVisibleProfile}
              oauthAction="signin"
            />
            <LoginOauthButton
              isVisible={showGithubButton && !isVisibleProfile}
              oauthType="github"
              onClick={() => onGitHub()}
            />
          </ButtonList>
          <LoginAttention />
        </LoginMenu>
      </Root>
    </GoogleOAuthProvider>
  )
}

const Root = styled.div`
  position: relative;
  background: #fff;
  height: 100vh;
  padding: 40px;

  @media screen and (max-width: 1000px) {
    padding: 20px;
  }
`

const LoginMenu = styled.div`
  position: absolute;
  top: 30%;
  right: 0;
  left: 0;
`

const ButtonList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 30px;
  margin: 30px auto 53px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 10px 0;
  }
`

export default Login
