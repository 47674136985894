import React from "react"
import styled from "styled-components"

import logoWorkbench from "../../assets/logo-workbench.svg"
import { t } from "../../lib/i18n"

type Props = {
  isFigmaPlugin: boolean
}

const LoginMain = ({ isFigmaPlugin }: Props): JSX.Element => {
  return (
    <>
      {isFigmaPlugin && (
        <StyledLogoContainer>
          <StyledLogo src={logoWorkbench} alt="Workbench" />
        </StyledLogoContainer>
      )}
      <StyledH1>{t("sign_in")}</StyledH1>
      <Description>{t("welcome_home")}</Description>
    </>
  )
}

const StyledLogoContainer = styled.div`
  text-align: center;
  margin-bottom: 25px;
`

const StyledLogo = styled.img`
  width: 166px;
`

const StyledH1 = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 29px;
  text-align: center;
  color: #171721;
  margin-bottom: 25px;
`

const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  color: #8a94a6;
  margin: 0;
`

export default LoginMain
